import { Outlet, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const isAuthenticated = (requiredRole) => {
  const token = localStorage.getItem("token"); 
  const decoded = jwtDecode(token); 
  const {role} = decoded; 
  return requiredRole === "admin" && role === requiredRole && localStorage.getItem("token") !== null;
};

const ProtectedRoutes = ({requiredRole}) => {
  return isAuthenticated(requiredRole) ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
