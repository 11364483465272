import React from 'react'

const Geojson = () => {
  return (
    <div>
      Geojson Map
    </div>
  )
}

export default Geojson;
