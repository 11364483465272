import React from "react";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import styled from "styled-components";
import { tokens } from "../theme";

// Styled Card component
const StyledCard = styled(Card)`
  width: 400px;
  height: 400px;
  text-align: center;
  border-radius: 50%; /* Circular shape */
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); /* Box shadow */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; /* Remove padding to ensure content fits */
`;

const QuoteTypography = styled(Typography)`
  text-align: center;
  padding: 0 20px; /* Add padding for inner spacing */
  font-size: 1.2rem;
  overflow-wrap: break-word; /* Ensure text wraps properly */
`;

const Loading = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Array of quotes related to autonomous farmer's trucks
  const quotes = [
    "In the heart of fields, autonomous trucks pave the way, revolutionizing farming with precision technology.",
    "Across vast landscapes, autonomous vehicles redefine efficiency, driving sustainable farming practices forward.",
    "Empowering farmers with innovation, autonomous trucks navigate fields, shaping the future of agriculture.",
    "Amidst fertile soil, autonomous solutions emerge, transforming traditional farming with cutting-edge technology.",
    "Fields come alive with the hum of autonomous trucks, ushering in a new era of farming productivity.",
    "The landscape shifts as autonomous vehicles take the wheel, leading the charge in agricultural transformation.",
    "In the digital age, automated farming reimagines precision, enhancing productivity and shaping the future.",
    "From seed to harvest, autonomous solutions optimize crop yields, revolutionizing resource management.",
    "Technology's embrace transforms the agricultural landscape, as autonomous farming becomes the cornerstone of innovation.",
    "Across the countryside, autonomous farmer's trucks roll, heralding a greener, smarter future for agriculture.",
  ];

  // Randomly select a quote from the array
  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

  // Split the quote into multiple lines
  const quoteLines = randomQuote.split(',');

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <StyledCard sx={{height:"400px" , width:"400px", borderRadius:"50%", bgcolor:"#0e1117", color:colors.grey[600]}}>
        <CardContent sx={{ padding: 0 }}>
          {quoteLines.map((line, index) => (
            <QuoteTypography variant="h3" key={index}>
              {line}
            </QuoteTypography>
          ))}
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default Loading;
