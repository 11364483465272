import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  LayersControl,
  Popup,
  Polyline,
  useMap,
} from "react-leaflet";
import { LatLng } from "leaflet";
import { Icon } from "leaflet";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  Paper,
  FormControl,
  FormLabel,
  Autocomplete,
} from "@mui/material";
import { toast } from "react-toastify";
import { useFile } from "../../contexts/FileContext";

const ChangeView = ({ center, zoom, setIsChange }) => {
  const map = useMap();
  map.setView(center, zoom);
  setIsChange(false);
  return null;
};

const CreateMap = () => {
  const [filename, setFilename] = useState("");
  const [savepathJSON, setSavepathJSON] = useState("");
  const [pathCoordinates, setPathCoordinates] = useState([]);
  const [jsonpathCoordinates, setJsonPathCoordinates] = useState([]);
  const [selectedOption, setSelectedOption] = useState("USBrentWoodFarm");
  const [isChange, setIsChange] = useState(false);

  const coordinates = {
    TadaFarm: [13.585014, 79.961858],
    Palamangalam: [13.398352763778135,79.67893626609158],
    California: [37.28812010891868, -121.79817460138521],
    NZHaveLockNorth: [-39.659442777674826, 176.9052409750602],
    USBrentWoodFarm: [37.94991683095781, -121.6516979499679],
  };
  const [center, setCenter] = useState(
    new LatLng(coordinates.USBrentWoodFarm[0], coordinates.USBrentWoodFarm[1])
  );

  const [markers, setMarkers] = useState([
    {
      name: "Boson Truck",
      icon: new Icon({
        iconUrl: "/img/blackdrag.png",
        iconSize: [40, 40],
      }),
      position: center,
    },
    {
      name: "Autonomous Farm Truck",
      icon: new Icon({
        iconUrl: "/img/yellowDrag.png",
        iconSize: [60, 60],
      }),
      position: center,
    },
  ]);
  const location = useLocation();
  const navigate = useNavigate();
  let { folderId } = location.state;
  const {
    fetchVehicleStatusAndFiles,
    vehicle,
    assignedVehicles,
    handleSelection,
  } = useFile();
  let assignVehicles = assignedVehicles; 
  if(vehicle.id) folderId = vehicle.id; 
  console.log(folderId);
  if (!assignVehicles)
    assignVehicles = JSON.parse(localStorage.getItem("assignedVehicles"));
  // console.log(assignVehicles);

  const tileLayerUrls = {
    googleStreets: "http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}",
    googleSatellite: "http://{s}.google.com/vt?lyrs=s&x={x}&y={y}&z={z}",
    googleHybrid: "http://{s}.google.com/vt?lyrs=s,h&x={x}&y={y}&z={z}",
    googleTerrain: "http://{s}.google.com/vt?lyrs=p&x={x}&y={y}&z={z}",
  };

  const handleChange = (event, value) => {
    console.log(value);
    setSelectedOption(value);
    const newCenter = new LatLng(coordinates[value][0], coordinates[value][1]);
    setIsChange(true);
    setCenter(newCenter);
  };

  const handleMarkerDrag = (index, newPosition) => {
    const { lat, lng } = newPosition;
    //Line path
    const newPathCoordinate = [...pathCoordinates, [lat, lng]];
    setPathCoordinates(newPathCoordinate);
    //GeoJson path
    const updatedPathCoordinate = [...jsonpathCoordinates, [lng, lat]];
    setJsonPathCoordinates(updatedPathCoordinate);

    const updatedMarkers = markers.map((marker, i) => {
      if (i === index) {
        return { ...marker, position: newPosition };
      }
      return marker;
    });

    setMarkers(updatedMarkers);

    // Generate geoJSON path
    const path = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: updatedPathCoordinate,
      },
      properties: {},
    };

    setSavepathJSON(JSON.stringify(path, null, 2));
  };

  const handleUpload = async (folderId) => {
    try {
      const formData = new FormData();
      console.log(savepathJSON)
      const blob = new Blob([savepathJSON], { type: "application/json" });
      const file = new File([blob], filename, { type: "application/json" });
      formData.append("file", file);
      formData.append("parent_id", folderId);
      if (savepathJSON === "") {
        return toast.warn("Empty savepath..");
      } else if (filename === "") {
        return toast.warn("Filename is Empty..");
      }
      const VehicleId = localStorage.getItem("VehicleId"); 
      await axios.post(
        `${process.env.REACT_APP_API_URL}/file/upload/${VehicleId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await fetchVehicleStatusAndFiles(folderId, vehicle);
      setSavepathJSON("");
      setFilename("");
      toast.success(`${filename} is uploaded successfully....`);
      navigate("/fetchFiles");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleFilenameChange = (e) => {
    setFilename(e.target.value);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            Boson SavePath Map
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Autocomplete
                options={Object.keys(coordinates)}
                value={selectedOption}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Location"
                    variant="outlined"
                  />
                )}
                style={{ width: "300px" }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={assignVehicles}
                value={vehicle}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Vehicles"
                    variant="outlined"
                  />
                )}
                onChange={handleSelection}
                sx={{ width: 300 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={4} sx={{ mt: 1 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 1, height: "80vh" }}>
            <MapContainer
              center={center}
              zoom={21}
              scrollWheelZoom={false}
              style={{ height: "100%", width: "100%" }}
            >
              {isChange && (
                <ChangeView
                  center={center}
                  zoom={21}
                  setIsChange={setIsChange}
                />
              )}
              <LayersControl>
                <LayersControl.BaseLayer checked name="Satellite View">
                  <TileLayer
                    url={tileLayerUrls.googleSatellite}
                    maxZoom={20}
                    subdomains={["mt1", "mt2", "mt3"]}
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer name="Hybrid View">
                  <TileLayer
                    url={tileLayerUrls.googleHybrid}
                    maxZoom={20}
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer name="Street View">
                  <TileLayer
                    url={tileLayerUrls.googleStreets}
                    maxZoom={20}
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer name="Terrain View">
                  <TileLayer
                    url={tileLayerUrls.googleTerrain}
                    maxZoom={20}
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  />
                </LayersControl.BaseLayer>
                {markers.map((marker, index) => (
                  <LayersControl.Overlay
                    key={index}
                    name={marker.name}
                    checked={marker.visible}
                  >
                    <Marker
                      position={center}
                      icon={marker.icon}
                      draggable={true}
                      eventHandlers={{
                        drag: (e) =>
                          handleMarkerDrag(index, e.target.getLatLng()),
                      }}
                    >
                      <Popup>{marker.name + "->" + center}</Popup>
                    </Marker>
                  </LayersControl.Overlay>
                ))}
                {pathCoordinates.length > 1 && (
                  <Polyline positions={pathCoordinates} color="blue" />
                )}
              </LayersControl>
            </MapContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              p: 2,
              height: "80vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <FormControl fullWidth>
              <FormLabel component="legend">Filename (with extension .json or .geojson)</FormLabel>
              <TextField
                fullWidth
                value={filename}
                onChange={handleFilenameChange}
                variant="outlined"
                margin="normal"
              />
            </FormControl>
            <FormControl sx={{ mt: 1 }}>
              <FormLabel component="legend">Savepath JSON</FormLabel>
              <textarea
                value={savepathJSON}
                onChange={(e) => setSavepathJSON(e.target.value)}
                rows={25}
                placeholder="Savepath JSON"
                style={{
                  width: "100%",
                  height: "calc(100% - 16px)",
                  resize: "none",
                  marginTop: "8px",
                }}
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpload(folderId)}
              sx={{ mt: 2, width: "100%" }}
            >
              Upload Savepath
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateMap;
