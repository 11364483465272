import { createContext, useContext, useCallback, useState, useEffect } from "react"; 
import { format } from "date-fns";
import axios from "axios"; 

import { toast } from "react-toastify";

// let Vehicles = [
//     { id: "caqpnb670a9b06da04d35bad0b094b92d9474", label: "P1 Vehicle" },
//     { id: "awdq6ce5a72516b054d7f840b4a0708f8ff91", label: "T6 Vehicle" },
//     { id: "jfacwdbbdb0f4d5c1497c9402efa2e8f4283b", label: "T7 Vehicle" },
//     { id: "4dqt72086d7f4e83c4789ad8e350ebda1738e", label: "T8 Vehicle" },
//     { id: "10suq14fb01cc02054c99acfe72713660ab18", label: "T9 Vehicle" },
//     { id: "3bwzx0bc7342211d24484b53cec436e4253cb", label: "P7 Vehicle" },
//     { id: "0khec1dc062ffe77645cf8243805fc2473e18", label: "US HC Fargo" },
//     { id: "tp2st64ec0904d2e04a19a60c4fb00df34ddb", label: "Yellow Porter" },
//     { id: "r4o8u36dcec6e65f4445fb977fc3b146d3150", label: "US HC Yuba" },
//     { id: "awdq6a767319b4e994428a00ca2ee72bea6e8", label: "Black Dragon" },
//     { id: "awdq68cda289bac8240c2b459d6c6c077404d", label: "Yellow Dragon" },
//     { id: "ecsvi266337bb4aa34521a1f77ee3e332c980", label:"demo"},
// ];

let Vehicles = [];

let vehicles = [];

const FileContext = createContext(); 

export const FileProvider = ({children})=>{

    const [fileData, setFileData] = useState({}); 
    const [folderId, setFolderId] = useState(""); 
    const [vehicle, setVehicle] = useState(null); 
    const [assignedVehicles, setAssignedVehicles] = useState(null); 
    const [spinner, setSpinner] = useState(false); 
    const [isLoggedIn, setIsLoggedIn] = useState(false); 
    const [allVehicles, setAllVehicles] = useState([]);
    const [categorizeVehicles, setCategorizeVehicles] = useState({
        "India Vehicles": [], 
        "USA Vehicles":[]
    });

    const [vehicleStatus, setVehicleStatus] = useState({
        "Last Online Time":"", 
        "Online Status":""
    }); 

    const formatLastOnlineTime = (nanoseconds)=>{
        const milliseconds = nanoseconds/1e6; 
        const date = new Date(milliseconds); 
        return format(date, 'PPpp');
    }

    const Login = async(data)=>{
        try{
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`,data);
        console.log(response.data); 
        const {token, vehicleNames} = response.data; 
        vehicles = Vehicles.filter((item)=> vehicleNames.includes(item.label)); 
        setIsLoggedIn(true);
        setAssignedVehicles(vehicles); 
       
        localStorage.setItem("token", token);
        localStorage.setItem("assignedVehicles", JSON.stringify(vehicles)); 
        console.log(vehicles); 
        toast.success("Login successfully");
      }catch(err){
        // console.log("Error when submitting the form",err);
        const storeAssignedVehicle = localStorage.getItem("assignedVehicles"); 
        if(!storeAssignedVehicle) toast.warn("No vehicles is assigned to you. Please wait for sometime.");
        else toast.error("User can't Login.");
      }
    } 

    const fetchVehicleStatusAndFiles = useCallback(async(folderId, vehicle)=>{
        try{
          setSpinner(true);
          if (folderId !== "ecsvi266337bb4aa34521a1f77ee3e332c980") {
            const vehicleStats = await axios.get(
              `${process.env.REACT_APP_API_URL}/status/${vehicle.label}`
            );
            const {
              "Last Online Time": lastOnlineTime,
              "Online Status": onlineStatus,
              VehicleID,
            } = vehicleStats.data[0];

            setVehicleStatus({
              VehicleId: VehicleID,
              "Last Online Time": formatLastOnlineTime(lastOnlineTime),
              "Online Status": onlineStatus,
            });
            localStorage.setItem("VehicleId", VehicleID);
            localStorage.setItem(
              "lastOnlineTime",
              formatLastOnlineTime(lastOnlineTime)
            );
            localStorage.setItem("onlineStatus", onlineStatus);
          }
          if (folderId ==="ecsvi266337bb4aa34521a1f77ee3e332c980") {
              localStorage.setItem("VehicleId", "MD9DMUE25DC342003");
          }

          const fileDataResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/teamfolders50/${folderId}/files`
          );
          const data = fileDataResponse.data;
          setFileData(data);
          setFolderId(folderId);
          setVehicle(vehicle);

          localStorage.setItem("fileData", JSON.stringify(data));
          localStorage.setItem("folderId", folderId);
          localStorage.setItem("vehicle", JSON.stringify(vehicle));
          setSpinner(false);
        }catch(err){
            console.error(err);
        }
    }, []); 


    const handleSelection = async(event, value)=>{
        if(value){
            event.preventDefault(); 
            await fetchVehicleStatusAndFiles(value.id, value);
        }
    }; 

    

    useEffect(()=>{
       const vehicleDetails = async()=>{
        try{
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/teamfolders/awdq6d088595db65c4c1a998ea9f848830ec3/subfolders`);  
         res.data.forEach((item) => {
           if (!item.label.includes("Backup")) {
             Vehicles.push(item);
           }
         });
          const demoitem = {id: "ecsvi266337bb4aa34521a1f77ee3e332c980", label:"demo"};
          Vehicles.push(demoitem);

          setAllVehicles(Vehicles);
          console.log(Vehicles);
        }catch(err){
            console.log(err); 
        }
    }
    vehicleDetails();
    },[])


    
    return(
        <FileContext.Provider 
          value={{
            fileData, 
            folderId, 
            vehicle, 
            spinner, 
            vehicleStatus, 
            assignedVehicles,
            isLoggedIn,
            categorizeVehicles,
            allVehicles,
            Login,
            setFileData, 
            setFolderId, 
            setVehicle, 
            setSpinner, 
            setVehicleStatus,
            setCategorizeVehicles, 
            handleSelection, 
            setAllVehicles,
            fetchVehicleStatusAndFiles
          }}
        
        >
            {children}
        </FileContext.Provider>
    )

}; 

export const useFile = ()=>{
    return useContext(FileContext);
}
