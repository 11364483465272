import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import BatteryGauge from "react-battery-gauge";
import ReactSpeedometer from "react-d3-speedometer";

const BatteryBox = ({ title, icon, value }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      width="100%"
      m="0 30px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="70%"
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          {title}
        </Typography>
        <Box mb="22px">
          {title === "DistanceTraveled(kms)" ? (
            <Box sx={{ width: "25%"}}>
              <ReactSpeedometer
                maxValue={3000}
                value={value}
                needleColor="steelblue"
                startColor="green"
                segments={10}
                needleTransitionDuration={4000}
                needleTransition="easeElastic"
                segmentColors={["firebrick", "tomato", "gold", "limegreen"]}
                endColor="blue"
                ringWidth={30}
                width={220}
                height={150}
              />
            </Box>
          ) : (
            <BatteryGauge
              customization={
                title === "Battery SOH"
                  ? {
                      batteryMeter: {
                        noOfCells: 5,
                      },
                    }
                  : ""
              }
              value={value}
              size={150}
              animated={true}
              padding={5}
            />
          )}
        </Box>
      </Box>
      {icon && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="30%"
        >
          {icon}
        </Box>
      )}
    </Box>
  );
};

export default BatteryBox;
