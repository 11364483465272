import {
  MapContainer,
  Marker,
  Popup,
  Polyline,
  TileLayer,
  FeatureGroup,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { useState, useEffect, useRef } from "react";
import { LatLng } from "leaflet/src/geo/LatLng";
import { DOMParser } from "xmldom";
import nextId, { resetId } from "react-id-generator";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { EditControl } from "react-leaflet-draw";

// Ensure Leaflet icons load correctly
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const OSMDataLayer = ({ data, geojsondata, lati, longi }) => {
  const mapRef = useRef();

  const [xmlData, setXmlData] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [ways, setWays] = useState(null);

  const center = new LatLng(lati, longi);

  const [loadingFile, setLoadingFile] = useState(true);
  const [loadingNode, setLoadingNode] = useState(true);
  const [loadingWay, setLoadingWay] = useState(true);

  const featureGroupRef = useRef();
  const [updatedGeoJSON, setUpdatedGeoJSON] = useState(geojsondata);
  const [updatedPoints, setUpdatedPoints] = useState(); 
  const [markers, setMarkers] = useState([]);
  const [popupContents, setPopupContents] = useState({});

  useEffect(() => {
    const fetchData = () => {
      try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "application/xml");
        setXmlData(xmlDoc);
      } catch (error) {
        console.error("Error parsing XML file:", error);
      } finally {
        setLoadingFile(false);
      }
    };

    const getNodes = (xmlDoc) => {
      if (!xmlDoc) return;

      const result = {};
      const nodes = xmlDoc.getElementsByTagName("node");
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        const id = node.getAttribute("id");
        result[id] = [
          parseFloat(node.getAttribute("lat")),
          parseFloat(node.getAttribute("lon")),
        ];
      }
      setNodes(result);
      setLoadingNode(false);
    };

    const getWays = (xmlDoc, nodes) => {
      if (!xmlDoc || !nodes) return;

      const result = [];
      const ways = xmlDoc.getElementsByTagName("way");
      for (let i = 0; i < ways.length; i++) {
        const way = ways[i];
        const nds = way.getElementsByTagName("nd");
        const node_list = new Array(nds.length);
        for (let j = 0; j < nds.length; j++) {
          const ref = nds[j].getAttribute("ref");
          node_list[j] = nodes[ref];
        }
        result.push(node_list);
      }
      setWays(result);
      setLoadingWay(false);
    };

    if (!xmlData) fetchData();
    if (!nodes && xmlData) getNodes(xmlData);
    if (!ways && xmlData && nodes) getWays(xmlData, nodes);
  }, [xmlData, nodes, ways, data]);

  useEffect(() => {
    if (mapRef.current && ways && ways.length > 0) {
      const bounds = calculateBounds(ways);
      mapRef.current.fitBounds(bounds);
    }
  }, [ways]);

  const calculateBounds = (polylines) => {
    let bounds = L.latLngBounds([]);
    polylines.forEach((polyline) => {
      polyline.forEach((point) => {
        bounds.extend(L.latLng(point[0], point[1]));
      });
    });
    return bounds;
  };

  const tileLayerUrls = {
    googleStreets: "http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}",
    googleSatellite: "http://{s}.google.com/vt?lyrs=s&x={x}&y={y}&z={z}",
    googleHybrid: "http://{s}.google.com/vt?lyrs=s,h&x={x}&y={y}&z={z}",
    googleTerrain: "http://{s}.google.com/vt?lyrs=p&x={x}&y={y}&z={z}",
  };

  const handleMarkerClick = (markerId) => {
    setPopupContents((prevPopupContents) => ({
      ...prevPopupContents,
      [markerId]: prevPopupContents[markerId] || [{ key: "", value: "" }],
    }));
  };

  const onCreated = (e) => {
    const { layerType, layer } = e;
    console.log(e); 
    if (layerType === "marker") {
      const markerCoords = layer.getLatLng();
      const markerId = nextId()[2];

      const newMarker = {
        markerId: markerId,
        lat: markerCoords.lat,
        lng: markerCoords.lng,
        properties: [],
      };

      setMarkers((prevMarkers) => [...prevMarkers, newMarker]);
      setPopupContents((prevPopupContents) => ({
        ...prevPopupContents,
        [markerId]: [],
      }));
    }
  };

  const handleAddRow = (markerId) => {
    setPopupContents((prevPopupContents) => ({
      ...prevPopupContents,
      [markerId]: [...prevPopupContents[markerId], { key: "", value: "" }],
    }));
  };

 const handleSave = (markerId) => {
    const marker = markers.find((m) => m.markerId === markerId);
    if (marker) {
        const updatedMarkers = markers.map((m) =>
            m.markerId === markerId
                ? { ...m, properties: popupContents[markerId] }
                : m
        );

        setMarkers(updatedMarkers);

        const newFeatures = updatedMarkers.map((marker) => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [marker.lng, marker.lat],
            },
            id: marker.markerId,
            properties: marker.properties.reduce((acc, { key, value }) => {
                acc[key] = value;
                return acc;
            }, {}),
        }));

        const updatedGeoJSONFeatureCollection = {
            type: "FeatureCollection",
            features: [...geojsondata.features, ...newFeatures],
        };

        const updatedPointCollection = {
          type:"FeatureCollection", 
          features: [...newFeatures,],
        }
        setUpdatedPoints(updatedPointCollection);

        setUpdatedGeoJSON(updatedGeoJSONFeatureCollection);
    }
};

 
 const handleExport = (data) => {
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(data));
    const filename = "webmap___" + Date.now() + ".geojson";
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    resetId();
  };

  if (loadingFile || loadingNode || loadingWay) {
    return (
      <MapContainer
        style={{ height: 600 }}
        center={center}
        zoom={18}
        scrollWheelZoom={true}
      />
    );
  } else {
    return (
      <Grid container spacing={4} sx={{ mb: 5 }}>
        <Grid item xs={12} md={12}>
          <Paper sx={{ p: 1, height: "80vh" }}>
            <MapContainer
              ref={mapRef}
              style={{ height: "100%", width: "100%" }}
              center={center}
              zoom={19}
              scrollWheelZoom={true}
            >
              <LayersControl>
                <LayersControl.BaseLayer checked name="Satellite view">
                  <TileLayer
                    url={tileLayerUrls.googleSatellite}
                    maxZoom={30}
                    subdomains={["mt1", "mt2", "mt3"]}
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Hybrid View">
                  <TileLayer
                    url={tileLayerUrls.googleHybrid}
                    maxZoom={20}
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer name="Street View">
                  <TileLayer
                    url={tileLayerUrls.googleStreets}
                    maxZoom={20}
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer name="Terrain View">
                  <TileLayer
                    url={tileLayerUrls.googleTerrain}
                    maxZoom={20}
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  />
                </LayersControl.BaseLayer>

                <FeatureGroup ref={featureGroupRef}>
                  {geojsondata && (
                    <EditControl
                      position="topright"
                      onCreated={onCreated}
                      onEdited={(e) => {
                        const { layers } = e;
                        layers.eachLayer(function (layer) {
                          console.log(layer);
                        });
                        setUpdatedGeoJSON(layers.toGeoJSON());
                      }}
                      onDeleted={(e) => {
                        const { layers } = e;
                        setUpdatedGeoJSON(layers.toGeoJSON());
                      }}
                      edit={{
                        featureGroup: featureGroupRef.current,
                      }}
                    />
                  )}

                  {ways.map((points, index) => (
                    <Polyline
                      ref={featureGroupRef}
                      key={index}
                      color={"#02f527"}
                      opacity={0.9}
                      weight={2.5}
                      positions={points}
                    />
                  ))}
                  {markers.map((marker) => (
                    <Marker
                      key={marker.markerId}
                      position={[marker.lat, marker.lng]}
                      eventHandlers={{
                        click: () => {
                          handleMarkerClick(marker.markerId);
                        },
                      }}
                    >
                      <Popup>
                        <div>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Value</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(popupContents[marker.markerId] || []).map(
                                (row, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <input
                                        type="text"
                                        placeholder="Key"
                                        value={row.key}
                                        onChange={(e) => {
                                          const newContent = [
                                            ...popupContents[marker.markerId],
                                          ];
                                          newContent[index].key =
                                            e.target.value;
                                          setPopupContents((prevContents) => ({
                                            ...prevContents,
                                            [marker.markerId]: newContent,
                                          }));
                                        }}
                                        sx={{ width: "50%" }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <input
                                        type="number"
                                        placeholder="Value"
                                        value={row.value}
                                        onChange={(e) => {
                                          const newContent = [
                                            ...popupContents[marker.markerId],
                                          ];
                                          newContent[index].value = parseInt(
                                            e.target.value,
                                            10
                                          );
                                          setPopupContents((prevContents) => ({
                                            ...prevContents,
                                            [marker.markerId]: newContent,
                                          }));
                                        }}
                                        style={{ width: "65px", height:"45px",borderRadius:"10px" }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                          <Button onClick={() => handleAddRow(marker.markerId)}>
                            Add Row
                          </Button>
                          <Button
                            onClick={() => handleSave(marker.markerId)}
                            sx={{ bgcolor: "green" }}
                          >
                            Save
                          </Button>
                          <Button sx={{ bgcolor: "red" }}>Cancel</Button>
                        </div>
                      </Popup>
                    </Marker>
                  ))}
                </FeatureGroup>
              </LayersControl>
            </MapContainer>
            <Button
              onClick={()=>handleExport(updatedPoints)}
              sx={{
                bgcolor: "lightblue",
                position: "absolute",
                bottom: "10px",
                left: "10px",
                zIndex: 1000,
              }}
            >
              Export Points
            </Button>
            <Button
              onClick={()=>handleExport(updatedGeoJSON)}
              sx={{
                bgcolor: "lightgreen",
                position: "absolute",
                bottom: "10px",
                right: "10px",
                zIndex: 1000,
              }}
            >
              Export GeoJSON
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }
};

export default OSMDataLayer;
